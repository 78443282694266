<template>
    <div>
        <nav-bar></nav-bar>
        <router-view />
    </div>
</template>

<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    import Navbar from "@/components/navbar/nav.vue";
    export default {
        components: {
            "nav-bar": Navbar
        },
        setup() {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $success = inject("$success");
            const $error = inject("$error");
        }
    }
</script>