<template>
    <nav class="navbar is-fixed-top is-primary"
         role="navigation"
         aria-label="main navigation">
        <div class="container" id="nav-container">
            <div class="navbar-brand">
                <a class="navbar-item" :href="logoHref">
                    <img class="image smallLogo my-2"
                         src="@/assets/logos/aag-half.png" />
                    <img class="image bigLogo my-2"
                         src="@/assets/logos/aag-full.png" />
                </a>
                <a class="navbar-burger is-large"
                   role="button"
                   aria-label="menu"
                   aria-expanded="false"
                   data-target="navbar">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
            <div class="navbar-menu" id="navbar">
                <div class="navbar-end">
                    <ul class="legacy-nav-links">
                        <li><a href="https://aagrilles.com/linear-bar-grilles">Linear Bar Grilles</a></li>
                        <li><a href="https://aagrilles.com/product-category/perforated-grilles/">Perforated Grilles</a></li>
                        <li><a href="https://aagrilles.com/j-bead">Plaster J-Bead&reg;</a></li>
                    </ul>
                    <router-link class="navbar-item notactive" to="/rfq">Request a Quote</router-link>
                    <!--<router-link class="navbar-item notactive" to="/rapidspec">Rapid Specification</router-link>-->

                    <router-link class="navbar-item notactive"
                                 v-show="!$store.getters.isLoggedIn"
                                 to="/login">
                        <o-icon pack="far"
                                icon="user"
                                size="medium"
                                style="padding-right: 5px"></o-icon>
                        Login
                    </router-link>
                    <div class="navbar-item has-dropdown is-hoverable"
                         v-show="$store.getters.isLoggedIn">
                        <a class="navbar-link nav-login">
                            <o-icon pack="far" icon="user" size="medium"></o-icon>
                            <p>{{ $store.getters.user.FirstName }}</p>
                        </a>

                        <div class="navbar-dropdown is-right">
                            <router-link class="navbar-item"
                                         v-show="$store.getters.isLoggedIn"
                                         to="/profile">Profile</router-link>
                            <router-link class="navbar-item"
                                         v-show="$store.getters.isLoggedIn"
                                         to="/dashboard">Dashboard</router-link>
                            <router-link class="navbar-item" 
                                         to="/grilles" 
                                         v-show="$store.getters.isLoggedIn">Saved Grilles</router-link>
                            <router-link class="navbar-item"
                                         to="/schedules"
                                         v-show="$store.getters.isLoggedIn">Saved Schedules</router-link>
                            <router-link class="navbar-item"
                                         v-show="$store.getters.isAdmin"
                                         to="/admin">Admin</router-link>
                            <router-link class="navbar-item"
                                         v-show="$store.getters.isLoggedIn"
                                         to="/logout">Logout</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>
<script>
    import {
        getCurrentInstance,
        ref,
        computed,
        watch,
        onMounted,
        inject,
    } from "vue";
    export default {
        props: {},
        emits: [],
        setup(props, context) {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $success = inject("$success");
            const $error = inject("$error");

            const logoHref = computed(() => {
                return "https://aagrilles.com";
            });

            const burger = ref({});
            const navContainer = ref({});

            const IsDropdown = () => {
                return burger.value.classList.contains("is-active");
            };

            const ToggleBurger = (el) => {
                const target = document.getElementById(el.dataset.target);
                el.classList.toggle("is-active");
                target.classList.toggle("is-active");
            };

            const SetupHamburger = () => {
                const navbarBurgers = Array.prototype.slice.call(
                    document.querySelectorAll(".navbar-burger"),
                    0
                );
                if (navbarBurgers.length > 0) {
                    navbarBurgers.forEach((el) => {
                        el.addEventListener("click", () => {
                            ToggleBurger(el);
                        });
                    });
                }
            };

            const showSmall = ref(false);

            onMounted(() => {
                SetupHamburger();
                burger.value = document.getElementsByClassName("navbar-burger")[0];
                navContainer.value = document.getElementById("nav-container");
                document.addEventListener("click", (event) => {
                    if (IsDropdown() && !navContainer.value.contains(event.target)) {
                        ToggleBurger(burger.value);
                    }
                });
            });


            return {
                logoHref,
                showSmall
            };
        },
    };
</script>

<style lang="scss" scoped>
    @import "@/assets/css/globalVars.scss";

    #navbar.nav-mobile {
        background-color: #777;
    }

    .navbar {
        z-index: 200;
        font-family: 'Vollkorn SC', serif;
        //text-shadow: $text-shadow-nav;

        & .navbar-burger.is-large {
            transform: scale(1.72) translateX(-33%);
            margin-right: -0.525rem;
            margin-top: 1.2rem;
        }

        & .navbar-dropdown .navbar-item {
            padding: 0.25em 1em !important;
        }

        & .nav-login {
            @media screen and (max-width: 1023px) {
                display: none;
            }
        }

        & .navbar-item {
            padding: 0 1em;

            & img {
                max-height: $nav-height;
                //filter: drop-shadow(1px 1px 2px black);
                $nav-break-logo: 40rem;

                &.smallLogo {
                    display: initial;

                    @media screen and (min-width: $nav-break-logo) {
                        display: none;
                    }
                }

                &.bigLogo {
                    display: none;

                    @media screen and (min-width: $nav-break-logo) {
                        display: initial;
                    }
                }
            }

            &:hover {
                background-color: rgba(0, 0, 0, 20%) !important;
            }
        }

        & .legacy-nav-links {
            display: flex;
            flex-direction: row;
            gap: 0.3rem;
            margin-right: 1em;
            font-size: 0.875rem;
            font-family: Lora;
            font-weight: bold;
            //letter-spacing: -0.05rem;

            & > li {
                display: flex;
                flex-direction: column;
                justify-content: center;

                & > a {
                    display: inline-block;
                    border: 1px solid white;
                    border-radius: 2px;
                    padding: 0.4rem 0.3rem 0.3rem 0.3rem;
                    background-color: white;
                    color: #233d5d;
                    transition: background 200ms linear, color 200ms linear, border 200ms linear;

                    &:hover {
                        background-color: transparent;
                        color: white;
                    }
                }
            }
        }
    }
</style>
